<template lang="pug">
v-dialog(v-model='dialogVisible' scrollable persistent :fullscreen='$vuetify.breakpoint.xs' :hide-overlay='$vuetify.breakpoint.xs' max-width="1200px" :retain-focus="false")
  template(v-slot:activator='{ on, attrs }')
    v-btn(icon v-bind='attrs' v-on='on')
      v-icon(color='accent' size='22px') mdi-pencil
  v-form( ref="conditionsForm" v-model="valid" lazy-validation :key="formKey")
    v-card.d-flex.flex-column(min-height="70vh")
      div
        v-toolbar(flat color="transparent")
          v-toolbar-title.text-h6.dialog-title.font-weight-bold
            | Tätigkeit bearbeiten
          v-spacer
          v-btn(icon @click='cancel')
            v-icon mdi-close
        v-divider
      UpdateConditionsForm(v-model="conditionsItem")
      v-spacer
      v-divider
      v-card-actions.px-4
          v-spacer
          v-btn(color='grey' text @click='cancel')
            | Abbrechen
          v-btn.white--text(color='accent darken-1' :disabled="!valid" depressed @click='setConditions')
            | Speichern
</template>

<script>
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import UpdateConditionsForm from '@/components/business/offering/conditions/UpdateConditionsForm.vue';

export default Vue.extend({
  components: {
    UpdateConditionsForm,
  },
  props: ['conditions', 'positionName', 'index'],
  data() {
    return {
      formKey: Math.random(),
      valid: false,
      dialogVisible: false,
      conditionsItem: null,
    };
  },
  created() {
    this.setConditionsItem();
  },
  methods: {
    setConditionsItem() {
      const currentConditions = cloneDeep({
        ...this.conditions,
        title: cloneDeep(this.positionName),
      });

      this.conditionsItem = {
        title: currentConditions.title,
        jobType: currentConditions.jobType[0] || '', //[]
        startDate: currentConditions.startDate || '',
        workingTime: currentConditions.workingTime[0] || '', //[]
        duration: currentConditions.duration[0] || '', //[]
        nationality: currentConditions.nationality || '',
        city: currentConditions.city || '',
        level: currentConditions.level[0] || '', //[]
        desiredSalary: currentConditions.desiredSalary || 0,
        vacationDays: currentConditions.vacationDays || 0,
        benefits: currentConditions.benefits || [],
        areas: currentConditions.areas || [],
      };
    },
    cancel() {
      this.title = cloneDeep(this.positionName);

      this.dismissDialog();
    },
    dismissDialog() {
      this.setConditionsItem();
      this.dialogVisible = false;
    },
    setConditions() {
      if (!this.$refs.conditionsForm.validate()) {
        return;
      }

      const title = this.conditionsItem.title;

      const payload = {
        jobType: [this.conditionsItem.jobType],
        startDate: this.conditionsItem.startDate,
        workingTime: [this.conditionsItem.workingTime],
        duration: [this.conditionsItem.duration],
        nationality: this.conditionsItem.nationality,
        city: this.conditionsItem.city,
        level: [this.conditionsItem.level],
        desiredSalary: this.conditionsItem.desiredSalary,
        vacationDays: this.conditionsItem.vacationDays,
        benefits: this.conditionsItem.benefits,
        areas: this.conditionsItem.areas,
      };

      this.$store
        .dispatch('business/partialUpdate', [
          {
            item: payload,
            key: 'offering',
          },
          {
            item: title,
            key: 'name',
          },
        ])
        .then(() => {
          this.dialogVisible = false;
        });
    },
  },
});
</script>
