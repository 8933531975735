<template lang="pug">
.Match
  v-row 
    v-col.pt-3
      MatchCard(v-if="match && !match.opponentRemoved" :match="match" :prevRoute="prevRoute")
  .mt-12(v-if="match && !match.opponentRemoved")
    Header(link="OfferingsBusiness" :title="`${match.position.company.name} bietet`" subtitle="Angebot" :percentage="Math.round(match.positionScore.percentage)")
  //- .d-flex.flex-column.align-sm-center.flex-sm-row.my-4
  //-   .headerIcon.d-flex.text-left.text-md-center.mb-0.pb-0
  //-     v-icon.headerIcon(:size='mobile ? "40px" : "3rem"', color='accent') $offerings
  //-   div
  //-     h2 Wir bieten
  //-     strong toll
  .conditions(v-if="match && !match.opponentRemoved")
    .elements
      CardConditions(:item="match.position.offering" :positionName="match.position.name" :loading="isLoading" :disableEdit="true")
      CardTasks(:items="match.position.tasks" :disableEdit="true")
      CardCompany(:company="match.position.company" :loading="isLoading" :disableEdit="true") 
      CardRecruiter(:disableEdit="true" :user="match.position.recruiter")
     
      
  
  .mt-12(v-if="match && !match.opponentRemoved")
    Header(link="ConditionsBusiness" :title="`${match.position.company.name} sucht`" subtitle="Anforderungen" :percentage="Math.round(match.candidateScore.percentage)")
  .offerings.mb-12
    .elements(v-if="match && !match.opponentRemoved")
      cardEducation(type='school' title='Gewünschte Schule' :educationItems="match.position.school" :disableEdit="true")
      cardEducation(type='education' title='Gewünschte(s) Ausbildung / Studium' :educationItems="match.position.education"  :disableEdit="true")
      cardExperience(type='practicalExperience' title='Gewünschte Praxiserfahrung' :experienceItems="match.position.practicalExperience"  :disableEdit="true")
      cardExperience(:type='ExperienceType.OTHER_EXPERIENCE' title='Gewünschte Auslandserfahrung' :experienceItems="match.position.otherExperience"  :disableEdit="true")
      cardSkill(:type='SkillType.LANGUAGES' title='Gewünschte Sprachen' :skillItems="orderedLanguages(match)"  :disableEdit="true")
      cardSkill(:type='SkillType.SOFTWARE' title='Gewünschte Software' :skillItems="orderedSoftwareSkills(match)"  :disableEdit="true")
      cardOtherRequirements(title='Weitere Anforderungen' :items="match.position.otherRequirements"  :disableEdit="true")
  v-card(v-if="!loadingMatch && (!match || match.opponentRemoved)")
    v-card-text.text-center Dieses Match ist leider nicht mehr verfügbar
</template>
<script>
import cardEducation from '@/components/business/lookingFor/educations/CardEducation.vue';
import cardExperience from '@/components/business/lookingFor/experiences/CardExperience.vue';
import cardSkill from '@/components/business/lookingFor/skills/CardSkill.vue';
import cardOtherRequirements from '@/components/business/lookingFor/other/OtherRequirements.vue';
import { UserType } from '@/types/user';
import { SkillType } from '@/types/skill';
import { ExperienceType } from '@/types/experience';
import CardConditions from '@/components/business/offering/conditions/CardConditions.vue';
import CardRecruiter from '@/components/business/offering/recruiter/CardRecruiter.vue';
import CardCompany from '@/components/business/offering/company/CardCompany.vue';
import CardTasks from '@/components/business/offering/tasks/CardTasks.vue';
import Header from '@/components/navigation/Header.vue';
import Vue from 'vue';
import MatchCard from '@/components/candidate/matches/MatchCard.vue';
import { orderSkillItems } from '@/utilities/Utils';

export default Vue.extend({
  components: {
    cardExperience,
    cardSkill,
    cardEducation,
    cardOtherRequirements,
    CardConditions,
    CardRecruiter,
    CardCompany,
    CardTasks,
    Header,
    MatchCard,
  },
  data() {
    return { ExperienceType, UserType, SkillType, loadingMatch: true };
  },
  computed: {
    match() {
      return this.$store.getters['candidate/matchById'](this.$route.params.mid);
    },
    isLoading() {
      return this.$store.state.candidate.loading;
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.$store
      .dispatch('candidate/fetchMatch', this.$route.params.mid)
      .finally(() => (this.loadingMatch = false));
  },
  methods: {
    orderedLanguages: function (match) {
      return orderSkillItems(match.position.languages ?? []);
    },
    orderedSoftwareSkills: function (match) {
      return orderSkillItems(match.position.softwareSkills ?? []);
    },
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    match: {
      immediate: true,
      handler(match) {
        if (match && !match.seen) {
          this.$store.dispatch('candidate/markMatchSeen', match.id);
        }
      },
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.margin-top
  margin-top: 20px
</style>
