<template lang="pug">
CardMobileDesktop(:isLoading="loading"  title="Unternehmen")
  UpdateCompanyDialog(slot="actionSlot" v-if="!disableEdit")
  CardCompanyItems.my-2(slot="inputSlot" v-if="business" :business="business" :disableEdit="disableEdit")

    
</template>

<script>
import UpdateCompanyDialog from './UpdateCompanyDialog.vue';
// import { Gender } from '@/types/user';
// import { formatDate } from '@/utilities/DateAndTime';
import CardCompanyItems from './CardCompanyItems';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import { cloneDeep } from 'lodash';

export default {
  components: {
    UpdateCompanyDialog,
    CardCompanyItems,
    CardMobileDesktop,
  },
  props: ['loading', 'disableEdit', 'company'],
  data() {
    return {};
  },
  computed: {
    business() {
      const company =
        this.company ?? cloneDeep(this.$store.state.business.company);
      company.address = {
        street: company.address ? company.address.street || '' : '',
        streetNr: company.address ? company.address.streetNr || '' : '',
        zip: company.address ? company.address.zip || '' : '',
        city: company.address ? company.address.city || '' : '',
      };
      return company;
    },
  },
  created() {
    this.$store.dispatch('business/fetchCompany');
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
</style>
