<template lang="pug">

  v-dialog( v-model='dialog' persistent=''  max-width="700" scrollable :fullscreen="mobile")
    template(v-slot:activator='{ on, attrs }')
        v-btn(icon v-bind='attrs' v-on='on')
            v-icon(color='accent') mdi-pencil
    v-form( ref="companyForm" v-model="valid" lazy-validation)  
      v-card
        v-toolbar(flat tile color="transparent")
            v-toolbar-title.text-h6.dialog-title.font-weight-bold  Unternehmen bearbeiten
            v-spacer 
            v-btn(icon @click='cancel')
                v-icon mdi-close
        v-divider
        UpdateCompanyForm(v-model="company")

        //- v-spacer
        v-divider
        v-card-actions.px-4
            v-spacer
            v-btn(color='grey ' text='' @click='cancel')
                | Abbrechen
            v-btn.white--text(color='accent darken-1' depressed='' :loading="loading" :disabled="!valid" @click='updateCompanyData')
                | Speichern

</template>
<script>
import UploadAvatarImage from '@/components/dialogs/UploadAvatarImage';
import { cloneDeep } from 'lodash';
import Avatar from '@/components/elements/Avatar.vue';

import UpdateCompanyForm from '@/components/business/offering/company/UpdateCompanyForm.vue';
export default {
  components: {
    UploadAvatarImage,
    Avatar,
    UpdateCompanyForm,
  },
  created() {
    this.setCompany();
  },
  data: () => ({
    valid: false,
    dialog: false,
    menu: false,
    company: null,
  }),
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    setCompany() {
      const currentCompany = cloneDeep(this.$store.state.business.company);
      const address = currentCompany.address ?? {
        street: '',
        streetNr: '',
        zip: '',
        city: '',
      };
      currentCompany.address = address;
      this.company = currentCompany;
    },
    cancel() {
      this.dismissDialog();
    },
    dismissDialog() {
      this.setCompany();
      this.dialog = false;
    },
    updateCompanyData() {
      if (this.$refs.companyForm.validate()) {
        this.$store
          .dispatch('business/updateCompany', this.company)
          .then(() => {
            this.dismissDialog();
          });
      }
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
</style>
