
import Row from '../../../cards/CardRow.vue';

import Vue, { PropType } from 'vue';
import { CardRowType } from '@/types/shared';
import { Company } from '@/types/company';
export default Vue.extend({
  components: {
    Row,
  },
  data() {
    return {
      CardRowType,
    };
  },
  props: {
    business: { type: Object as PropType<Company> },
    disableEdit: { type: Boolean, default: false },
  },
  computed: {},
  methods: {},
});
