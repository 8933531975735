<template lang="pug">
CardMobileDesktop(:isLoading="loading" title="Tätigkeit")
  UpdateConditionsDialog(slot="actionSlot" :conditions="item" :positionName="positionName" v-if="!disableEdit")
  div(slot="inputSlot")
    div.py-4
      Row(title='Jobtitel', :value='positionName'  :border="!!item" :type="CardRowType.TEXT")
      div(v-if="item")
        Row(title='Anstellungsart', :value='item.jobType[0]'  :border="true" :type="CardRowType.TEXT")
        Row(title='Beginn', :value='formatDateMonth(item.startDate)'  :border="true"  :type="CardRowType.TEXT")
        Row(title='Arbeitszeit', :value='item.workingTime[0]'  :border="true"  :type="CardRowType.TEXT")
        Row(title='Laufzeit', :value='item.duration[0]'  :border="true"  :type="CardRowType.TEXT")
        Row(title='Ort', :value='item.city && item.city.name' :type="CardRowType.TEXT"  :border="true")
        Row(title='Bereich', :value='selectedAreas'  :border="true"  :type="CardRowType.CHIPS")
        Row(title='Level', :value='item.level[0]' :type="CardRowType.TEXT"  :border="true")
        Row(title='Jahresgehalt', :value='formatCurrency(item.desiredSalary)' :type="CardRowType.TEXT"  :border="true")
        Row(title='Jahresurlaub', :value='formatVacationDays(item.vacationDays)' :type="CardRowType.TEXT"  :border="true")
        Row(title='Benefits', :value='item.benefits'  :type="CardRowType.CHIPS")
  
    
</template>

<script>
import Row from '../../../cards/CardRow.vue';
import { CardRowType } from '@/types/shared';
import {
  formatDate,
  formatDateMonth,
  formatVacationDays,
} from '@/utilities/DateAndTime';
import { formatCurrency } from '@/utilities/Formats';
import EmptyCardText from '@/components/cards/EmptyCardText';
import UpdateConditionsDialog from '@/components/business/offering/conditions/UpdateConditionsDialog';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import { notEmpty } from '@/utilities/Utils';

export default {
  components: {
    UpdateConditionsDialog,
    Row,
    CardMobileDesktop,
    EmptyCardText,
  },
  props: ['positionName', 'item', 'loading', 'disableEdit'],
  data() {
    return {
      CardRowType,
    };
  },
  computed: {
    selectedAreas() {
      const areaCategories =
        this.item.areas
          ?.map((area) => area.areaCategory)
          .filter(notEmpty)
          .map((category) => category.name) ?? [];

      const areas = this.item.areas?.map((a) => a.name) ?? [];
      return [...[...new Set(areaCategories)], ...areas];
    },
  },
  methods: {
    formatDate,
    formatCurrency,
    formatVacationDays,
    formatDateMonth,
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.divider
  margin: 15px 0 25px 0
</style>
