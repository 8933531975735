<template lang="pug">
  CardMobileDesktop(:isLoading="isLoading" title="Aufgaben") 
    UpdateTasksDialog(:items='items' slot='actionSlot' v-if="!disableEdit" :isAddDialog="items.length == 0")
    div.py-4(slot="inputSlot")
      v-row.align-center(v-if='items.length > 0')
        v-col.hidden-sm-and-down(cols='6', md='4')
          v-divider.hidden-sm-and-down(vertical)
        v-col(cols='12', md='8')
          ul(v-if='items.length > 0') 
            li.text-black( v-for='(item, index) in items' :key="index") {{item}}
        //- Row(title='' :type='CardRowType.TEXT' :value='`- ${item}`')
      EmptyCardText( v-if='items.length === 0')
</template>

<script>
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
import Row from '@/components/cards/CardRow.vue';
import EmptyCardText from '@/components/cards/EmptyCardText';
import { CardRowType } from '@/types/shared';
import UpdateTasksDialog from './UpdateTasksDialog.vue';

export default {
  components: {
    CardMobileDesktop,
    Row,
    EmptyCardText,
    UpdateTasksDialog,
  },
  props: ['type', 'items', 'disableEdit'],
  data() {
    return {
      dialogVisible: false,
      CardRowType,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.business.loading;
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
@import '@/assets/style/flag-icon.scss'
.skill-row
  margin: 0 !important

.no-padding
  padding: 0 !important

.no-vertical-padding
  padding: 0px 12px 0 12px !important

.level-caption
  @include uppercase-gray-bold-text(15px)

.ratingContainer
  max-height: 50px
</style>
