
import Row from '../../../cards/CardRow.vue';

import Vue, { PropType } from 'vue';
import { User } from '@/types/user';
import { CardRowType } from '@/types/shared';
export default Vue.extend({
  components: {
    Row,
  },
  data() {
    return {
      CardRowType,
    };
  },
  props: {
    user: { type: Object as PropType<User> },
  },
  computed: {},
  methods: {},
});
