<template lang="pug">
CardMobileDesktop(:isLoading="isLoading" title="Recruiter/in")
  UpdateRecruiterDialog(slot="actionSlot" v-if="!disableEdit")
  CardRecruiterItems.my-2(slot="inputSlot" v-if="currentUser" :user="currentUser")

    
</template>

<script>
import UpdateRecruiterDialog from './UpdateRecruiterDialog';
// import { Gender } from '@/types/user';
// import { formatDate } from '@/utilities/DateAndTime';
import CardRecruiterItems from './CardRecruiterItems';
import CardMobileDesktop from '@/components/cards/CardMobileDesktop';
export default {
  components: {
    UpdateRecruiterDialog,
    CardRecruiterItems,
    CardMobileDesktop,
  },
  props: ['user', 'disableEdit'],
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.user ?? this.$store.state.user.currentUser;
    },
    isLoading() {
      return this.$store.state.user.loading;
    },
  },
  methods: {},
};
</script>

<style lang="sass">
@import '@/assets/style/main'
// .profile-picture
//   border: solid $light-gray 1px
// .color-gray
//   color: rgba(0, 0, 0, 0.6)
// .left-col
//   border-right: solid $light-gray 1px

// .caption-text
//   @include uppercase-gray-bold-text(14px)

// .bottom-line
//   // border-bottom: solid $light-gray 1px

// .margin-sides
//   @media #{map-get($display-breakpoints, 'md-and-up')}
//     margin: 0 30px 0 15px !important
//     min-height: 116px

// .gender-icon
//   margin-right: 10px

// .gender-tick-icon
//   margin: 0 0 0 20px
</style>
