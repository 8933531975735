<template lang="pug">

  v-dialog( v-model='dialog' persistent='' max-width="700" scrollable :fullscreen="mobile")
    template(v-slot:activator='{ on, attrs }')
        v-btn(icon v-bind='attrs' v-on='on')
            v-icon(color='accent') mdi-pencil
    v-form( ref="recruiterForm" v-model="valid" lazy-validation :key="formKey")
      v-card.d-flex.flex-column
        div
          v-toolbar(flat color="transparent" tile)
            v-toolbar-title.text-h6.dialog-title.font-weight-bold  Recruiter/in bearbeiten
            v-spacer 
            v-btn(icon @click='dismissDialog')
                v-icon mdi-close
          v-divider
        UpdateRecruiterForm(v-model="currentUser")
        //- v-spacer
        v-divider
        v-card-actions.px-4
            v-spacer
            v-btn(color='grey ' text='' @click='dismissDialog')
                | Abbrechen
            v-btn.white--text(color='accent darken-1' depressed='' :loading="loading" :disabled="loading || !valid" @click='updateUserData')
                | Speichern

</template>
<script>
import { cloneDeep } from 'lodash';
import UpdateRecruiterForm from '@/components/business/offering/recruiter/UpdateRecruiterForm.vue';

window.locale = 'de';
export default {
  components: {
    UpdateRecruiterForm,
  },
  data: () => ({
    formKey: Math.random(),
    valid: false,
    dialog: false,
    currentUser: {},
  }),
  computed: {
    loading() {
      return this.$store.state.user.loading;
    },
    currentGender() {
      const current = this.genderSelectionOptions.findIndex(
        (item) => item.type === this.currentUser.gender
      );
      return current == -1 ? undefined : current;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  created() {
    this.setRecruiter();
  },
  methods: {
    setRecruiter() {
      this.currentUser = cloneDeep(this.$store.state.user.currentUser);
    },
    dismissDialog() {
      this.setRecruiter();
      this.dialog = false;
    },
    updateUserData() {
      if (!this.$refs.recruiterForm.validate()) {
        return;
      }

      const { firstName, lastName, phone, gender, photoURL } = this.currentUser;
      this.$store
        .dispatch('user/updateUser', {
          ...this.$store.state.user.currentUser,
          firstName,
          lastName,
          phone,
          gender,
          photoURL,
        })
        .then(() => {
          this.$store.commit('business/setGeneratingMatches', true);
          this.dismissDialog();
        });
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        const currentUser = cloneDeep(this.$store.state.user.currentUser);
        this.currentUser.firstName = currentUser.firstName;
        this.currentUser.lastName = currentUser.lastName;
        this.currentUser.phone = currentUser.phone;
        this.currentUser.gender = currentUser.gender;
      }
    },
  },
};
</script>
<style lang="sass">
@import "@/assets/style/main"
</style>
